@font-face {
    font-family: 'IRANSans';
    src: url('./assets/fonts/IRANSansWeb.eot');
    src: url('./assets/fonts/IRANSansWeb.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/IRANSansWeb.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IRANSansFaNum';
    src: url('./assets/fonts/IRANSansFaNum.svg#IRANSansFaNum') format('svg'),
    url('./assets/fonts/IRANSansFaNum.ttf') format('truetype'),
    url('./assets/fonts/IRANSansFaNum.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


html {
    overscroll-behavior-y: auto;
}


* {
    font-family: IRANSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

